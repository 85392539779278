footer {
    height:7.5vh;
    text-align: center;
    background: $firstColor;
    color: $white;
    display:flex;
    align-items:center;
    a {
        color: $white;
        transition: $transition;
        &:hover {
            color: $thirdColor;
        }
    }
    .scrollToTop {
        position: fixed;
        bottom:60px;
        right:20px;
    }
}

