@import 'partials/Mixins';


html {
    scroll-behavior: smooth;
}

ul, li {
    list-style:none;
    padding: 0;
    margin: 0;
}

ol {
    margin: 0;
}

h1, h2, h3, p {
    padding: 0;
    margin: 0;
}

h1, h2, h3 {
    font-family: $headings;
}

p, a, input, button {
    font-family: $paragraphs;
}

.wrapper {
    width:80%;
    margin: 0 auto;
    @include mqSmallTablet {
        width: 90%;
    }

}

img {
    width: 100%;
    
}

a {
    color:$firstColor;
    text-decoration:none;
}

.navButton {
    background:none;
    border:none;
    font-size:3rem;
    color:$firstColor;
    outline:none;
    &:hover {
        color:$thirdColor;
    }
}