.hero {
    background: url("../assets/heroPlaceholder.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 57.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        margin: 0 0 25px 0;
    }
}

.heroContent {
    .innerBox {
        padding: 50px;
        border: 1px rgba($white, 0.75) solid;
        @include mqSmallTablet {
            padding: 50px 0px;
        }
    }
}

.recommendationContent {
    .innerBox {
        padding: 10px;
        border: 1px rgba($white, 0.75) solid;
    }
}

.heroContent,
.recommendationContent {
    background: rgba($white, 0.65);
    text-align: center;
    width: 50%;
    padding: 10px;


    @include mqSmallTablet {
        width: 80%;
    }

    input,button {
        height: 40px;
        padding: 5px 15px;
        border: 1px rgba($black, 0.5) solid;
        
    }
    input {
        width: 65%;
        &:focus {
            box-shadow: 0px 0px 2px 2px $thirdColor;
        }
        @include mqTablet {
            width: 100%;
            max-width:300px;
        }
        @include mqMobile {
            max-width: 200px;
        }
        
    }
    button {
        text-transform: uppercase;
        width: calc(35% - 15px);
        margin-left: 15px;
        background-color: $firstColor;
        color: $secondColor;
        transition: $transition;
        &:hover, &:focus {
            background-color: $thirdColor;
        }
        @include mqTablet {
            width: 100%;
            max-width:300px;
            margin: 5px 0px;
        }
        @include mqMobile {
            max-width: 200px;
        }
    }
}

.recommendationContainer {
    display: flex;
    height: 35vh;
}

.restaurantRecommendation {
    width: calc(100% / 3);
    display:flex;
    justify-content: center;
    align-items:center;
}

.restaurantRecommendation.num1 {
    background: url("../assets/restaurantRecommendation1Placeholder.jpg");
}

.restaurantRecommendation.num2 {
    background: url("../assets/restaurantRecommendation2Placeholder.jpg");
}

.restaurantRecommendation.num3 {
    background: url("../assets/restaurantRecommendation3Placeholder.jpg");
}

.restaurantRecommendation.num1,
.restaurantRecommendation.num2,
.restaurantRecommendation.num3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}