@mixin mqTablet {
  @media (max-width: 900px) { 
    @content; 
  }
}

@mixin mqSmallTablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mqLargeMobile  {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin mqMobile {
  @media (max-width: 400px) { 
    @content; 
  }
}
