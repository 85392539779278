.searchResults {
    background-color: #ffffff;
    background-image: $svgBackground;
    padding-top: 40px;
    h2 {
        text-align: center;
        margin: 20px 0;
    }
}

.resultsContainer {
    position: relative;
    .loadingOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        top: 75px;
        p {
            display: inline-block;
            font-size: 20px;
            font-weight: bold;
            background: $white;
            padding: 20px;
            border-radius: 10px;
            border: 1px solid $black;
        }
    }
}

.resultFilters {
    text-align: center;

    form {
        height: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        @include mqTablet {
            height: auto;
        }
    }
    select {
        appearance: none;
        background-image: url(../assets/chevron.svg);
        background-repeat: no-repeat;
        background-position: 95%;
        background-size: 10%;
        border-radius: 0px;
        &:focus {
            box-shadow: 0px 0px 2px 2px $thirdColor;
        }
    }
    select, button {
        height: 100%;
        margin: 0 5px;
        padding: 5px 15px;
        border: 1px rgba($black, 0.5) solid;
        width: 175px;
        @include mqTablet {
            width: 50%;
            margin: 5px;
        }
        @include mqMobile {
            width: 90%;
        }
    }

    button {
        text-transform: uppercase;
        background-color: $firstColor;
        color: $secondColor;
        transition: 0.3s;
        @include mqTablet {
            height: 40px;
        }
        &:hover, &:focus {
            background-color: $thirdColor;
        }
    }
}

.searchResults ul {
    display: flex;
    flex-wrap: wrap;
    min-height: 30vh;
    margin: 20px 0px;
    li {
        width:calc((100% / 3) - 40px);
        margin: 20px;
        overflow:hidden;
        background:white;
        border:1px solid $black;
        border-radius:10px;

        @include mqSmallTablet {
            width: calc((100% / 2) - 40px);
        }
        
        @include mqLargeMobile {
            width: 100%;
        }


    }
    li > img {
        height:200px;
        object-fit:cover;
    }
    .restaurantCardContent {
        padding: 10px;
    }
    .restaurantName a:hover,
    .restaurantName a:focus {
        color:$thirdColor;
    }
    .yelpStarsAndLogo {
        display:flex;
        flex-wrap:wrap;
        align-items:flex-end;
    }
    .starRating {
        height:auto;
        width: 50%;
    }
    .yelpLogo {
        width: 50%;
        text-align:right;
    }
    .yelpLogo img {
        width: 80px;
    }
    a {
        transition: $transition;
    }
}