@import 'partials/Mixins';

.detailsContent {
  padding:40px 0px;
  background-color: #ffffff;
  background-image: $svgBackground;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  h2 {
    text-align: center;
    padding: 20px 0px;
  }
}


.restaurantDetails {
  background:$white;
  width: 47.5%;
  border:1px solid $black;
  border-radius:10px;
  position:relative;

  @include mqSmallTablet {
    width: 100%;
    margin-top: 50px;
  }

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .restaurantContactInfo, .restaurantReviews {
    padding: 20px 40px;
    @include mqTablet {
      padding: 20px 10px;
    }
  }
  .flexContainerRestaurantDetails {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .info {
    text-align:right;
    color:$firstColor;
  }
  .starRating {
    img {
      width: auto;
      height: 1rem;
      object-fit: contain;
    }
  }
}
.backBtnContainer {
    .backButton {
      display: flex;
      align-items: center;
      position: absolute;
      top:20px;
      left:20px;
    }
    .backText {
      display: none; 
      font-size: 1.5rem;
      width: 100%;
      margin-left: 10px;
      @include mqSmallTablet {
          display: block;
        }
    }
}
.reviewCard {
  border:1px solid $black;
  border-radius:10px;
  overflow: hidden;
  margin:0;
  padding:0;
  width:100%;
  img {
    display: inline-block;
    height: 25px;
    width: 25px;
  }
  a {
    transition: 0.6s;
    display: block;
    text-align: center;
  }
  &:hover a,
  &:focus-within a {
    color:$thirdColor;
  }

  p {
    padding: 10px;
  }
}

.nameOfUser {
  text-align: center;
  background: $firstColor;
  color: $white;
}

.bikeInfo {
  padding: 20px 40px;
  @include mqTablet {
    padding: 20px 10px;
  }
}

.bikeDetails {
  background:$white;
  width: 47.5%;
  border:1px solid $black;
  border-radius:10px;
  h3 {
    margin: 10px 0;
  }
  p {
    color:$firstColor;
    margin-bottom:40px;
  }
  ul, li {
    list-style-type: decimal;
    font-family:$paragraphs;
    list-style-position:inside;
    color:$firstColor;
  }
  @include mqSmallTablet {
    margin-top: 50px;
    width: 100%;
  }
}

.mapContainer {
  height:400px;
}

.carousel {
  width:100%;
  overflow:hidden;
  text-align:center;
  margin-top:10px;
  height:210px;
  ol {
    display:flex;
    justify-content:center;
    position:absolute;
    width:calc(100% - 80px);
    @include mqTablet {
      width:calc(100% - 20px);

    }
  }
  li {
    list-style:disc;
    width:20px;
    cursor:pointer;
    color:$firstColor;
  }
  li:hover, li:focus {
    color:$thirdColor;
  }
}